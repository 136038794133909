import { RouteObject } from "react-router";

import lazyWithRetry from "~/routes/lazy-with-retry";
import routeDefinitions from "~/routes/route-definitions";

const profile = routeDefinitions.profile;

// Parent is root /
export const profileRoutes: RouteObject[] = [
  {
    path: profile.path,
    lazy: lazyWithRetry(() => import("./profile")),
    children: [
      {
        path: profile.editPersonalInformation.path,
        lazy: lazyWithRetry(() => import("./profile.edit-personal-information")),
      },
      {
        path: profile.editPassword.path,
        lazy: lazyWithRetry(() => import("./profile.edit-password")),
      },
      {
        path: profile.editLanguageAndTimeZone.path,
        lazy: lazyWithRetry(() => import("./profile.edit-language-and-time-zone")),
      },
      {
        path: profile.activateTwoFactorAuthentication.path,
        lazy: lazyWithRetry(() => import("./profile.activate-two-factor-authentication")),
      },
      {
        path: profile.deactivateTwoFactorAuthentication.path,
        lazy: lazyWithRetry(() => import("./profile.deactivate-two-factor-authentication")),
      },
      {
        path: profile.verifyEmailAddress.path,
        lazy: lazyWithRetry(() => import("./profile.verify-email-address")),
      },
    ],
  },
];
