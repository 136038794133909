import { Navigate } from "react-router";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { isEmpty } from "lodash";

import routeDefinitions from "./route-definitions";

import { useComplianceChapters } from "~/features/chapter-finder/chapter-data-hooks/use-compliance-chapters";
import { useControlRoomChapters } from "~/features/chapter-finder/chapter-data-hooks/use-control-room-chapters";
import { useFinancialsChapters } from "~/features/chapter-finder/chapter-data-hooks/use-financials-chapters";
import { useOrdersChapters } from "~/features/chapter-finder/chapter-data-hooks/use-orders-chapters";
import { useOrganizationsChapters } from "~/features/chapter-finder/chapter-data-hooks/use-organizations-chapters";
import { usePeopleChapters } from "~/features/chapter-finder/chapter-data-hooks/use-people-chapters";
import { usePimChapters } from "~/features/chapter-finder/chapter-data-hooks/use-pim-chapters";
import { usePromotionsChapters } from "~/features/chapter-finder/chapter-data-hooks/use-promotions-chapters";
import { useStockChapters } from "~/features/chapter-finder/chapter-data-hooks/use-stock-chapters";
import { useTasksChapters } from "~/features/chapter-finder/chapter-data-hooks/use-tasks-chapters";
import { EModuleCode, IChapter } from "~/features/chapter-finder/chapter-finder.types";

const moduleChaptersHookMap: Record<EModuleCode, () => IChapter[]> = {
  [EModuleCode.CONTROLROOM]: useControlRoomChapters,
  [EModuleCode.FINANCIALS]: useFinancialsChapters,
  [EModuleCode.ORDERS]: useOrdersChapters,
  [EModuleCode.ORGANIZATIONS]: useOrganizationsChapters,
  [EModuleCode.PEOPLE]: usePeopleChapters,
  [EModuleCode.PIM]: usePimChapters,
  [EModuleCode.PROMOTIONS]: usePromotionsChapters,
  [EModuleCode.STOCKMANAGEMENT]: useStockChapters,
  [EModuleCode.COMPLIANCE]: useComplianceChapters,
  [EModuleCode.TASKS]: useTasksChapters,
};

const moduleBasePathsMap: Record<EModuleCode, string> = {
  [EModuleCode.CONTROLROOM]: routeDefinitions.controlRoom.module.path,
  [EModuleCode.FINANCIALS]: routeDefinitions.financials.module.path,
  [EModuleCode.ORDERS]: routeDefinitions.orders.module.path,
  [EModuleCode.ORGANIZATIONS]: routeDefinitions.organizations.module.path,
  [EModuleCode.PEOPLE]: routeDefinitions.people.module.path,
  [EModuleCode.PIM]: routeDefinitions.pim.module.path,
  [EModuleCode.PROMOTIONS]: routeDefinitions.promotions.module.path,
  [EModuleCode.STOCKMANAGEMENT]: routeDefinitions.stockManagement.module.path,
  [EModuleCode.COMPLIANCE]: routeDefinitions.compliance.module.path,
  [EModuleCode.TASKS]: routeDefinitions.tasks.module.path,
};

/**
 * Component that redirects to the first authorized chapter in the module or to unauthorized if no chapter is authorized
 */
const ModuleChapterRedirecter = ({ moduleCode }: { moduleCode: EModuleCode }) => {
  const isAuthorizationStructureLoading = hooks.useIsLoading({
    state: state.currentUser.currentUserAuthorizationStructureState.response,
  });
  const chapters = moduleChaptersHookMap[moduleCode]();

  return (
    <>
      {isAuthorizationStructureLoading ? null : (
        <>
          {isEmpty(chapters) ? (
            <Navigate to={routeDefinitions.auth.notAuthorized.path} replace />
          ) : (
            <Navigate to={`${moduleBasePathsMap[moduleCode]}${chapters[0].path}`} replace />
          )}
        </>
      )}
    </>
  );
};

export default ModuleChapterRedirecter;
