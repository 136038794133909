import { RouteObject } from "react-router";

import { adminSuiteConfig } from "../../../admin-suite.config";

import { NavigateWithParams } from "~/components/routing";
import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "~/routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "~/routes/lazy-with-retry";
import ModuleChapterRedirecter from "~/routes/module-chapter-redirecter";
import routeDefinitions from "~/routes/route-definitions";

const { compliance } = routeDefinitions;

const getAuditFilesRoutes = () => {
  let routes: RouteObject[] = [];

  if (adminSuiteConfig.auditFiles) {
    routes = [
      {
        path: compliance.auditFiles.overview.path,
        lazy: lazyWithRetry(() => import("./compliance.audit-files")),
        children: [
          {
            path: compliance.auditFiles.create.path,
            lazy: lazyWithRetry(() => import("./compliance.audit-files.new")),
          },
        ],
      },
    ];
  }
  return routes;
};

// Parent is root /
export const complianceRoutes: RouteObject[] = [
  {
    path: compliance.module.path,
    lazy: lazyWithRetry(() => import("./compliance")),
    children: [
      {
        index: true,
        element: <ModuleChapterRedirecter moduleCode={EModuleCode.COMPLIANCE} />,
      },
      {
        path: compliance.eventLedger.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./compliance.event-ledger")),
          },
          {
            path: compliance.eventLedger.details.path,
            lazy: lazyWithRetry(() => import("./compliance.event-ledger.$id")),
          },
        ],
      },
      {
        path: compliance.controlDocuments.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./compliance.control-documents"),
            import("./compliance.control-documents.$id"),
          ]);
          return page;
        }),
        children: [
          {
            path: compliance.controlDocuments.details.path,
            lazy: lazyWithRetry(() => import("./compliance.control-documents.$id")),
          },
        ],
      },
      {
        path: compliance.manualInvoices.overview.path,
        lazy: lazyWithRetry(() => import("./compliance.manual-invoices._index")),
      },
      {
        path: compliance.manualInvoices.new.path,
        lazy: lazyWithRetry(() => import("./compliance.manual-invoices.new")),
      },

      ...getAuditFilesRoutes(),

      {
        path: compliance.eInvoices.overview.path,
        lazy: lazyWithRetry(() => import("./compliance.e-invoices")),
      },

      {
        path: compliance.eInvoices.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./compliance.e-invoices.$id"),
            import("./compliance.e-invoices.$id.order-information"),
            import("./compliance.e-invoices.$id.history"),
            import("./compliance.e-invoices.$id.notifications"),
            import("./compliance.e-invoices.$id.exports"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams
                path={compliance.eInvoices.details.generalInformation.path}
                replace
              />
            ),
          },
          {
            path: compliance.eInvoices.details.generalInformation.path,
            lazy: lazyWithRetry(() => import("./compliance.e-invoices.$id.general-information")),
            children: [
              {
                path: compliance.eInvoices.details.generalInformation.editUserData.path,
                lazy: lazyWithRetry(
                  () => import("./compliance.e-invoices.$id.general-information.edit-user-data"),
                ),
              },
            ],
          },
          {
            path: compliance.eInvoices.details.orderInformation.path,
            lazy: lazyWithRetry(() => import("./compliance.e-invoices.$id.order-information")),
          },
          {
            path: compliance.eInvoices.details.documents.path,
            lazy: lazyWithRetry(() => import("./compliance.e-invoices.$id.documents")),
          },
          {
            path: compliance.eInvoices.details.history.path,
            lazy: lazyWithRetry(() => import("./compliance.e-invoices.$id.history")),
          },
          {
            path: compliance.eInvoices.details.notifications.path,
            lazy: lazyWithRetry(() => import("./compliance.e-invoices.$id.notifications")),
          },
          {
            path: compliance.eInvoices.details.exports.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./compliance.e-invoices.$id.exports"),
                import("./compliance.e-invoices.$id.exports.$exportId"),
              ]);
              return page;
            }),
            children: [
              {
                path: compliance.eInvoices.details.exports.details.path,
                lazy: lazyWithRetry(() => import("./compliance.e-invoices.$id.exports.$exportId")),
              },
            ],
          },
        ],
      },

      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },
];
