import { FormattedMessage, useIntl } from "react-intl";
import { ActionFunctionArgs, Form, redirect, useActionData, useNavigation } from "react-router";

import { Button, Card, CardContent, CardHeader, PasswordTextField } from "@new-black/lyra";
import { Core } from "@springtree/eva-services-core";
import { z } from "zod";
import { zfd } from "zod-form-data";

import { LogoIcon } from "~/components/shared/header/logo";
import { Page } from "~/components/shared/page";
import PageCenter from "~/components/shared/page-center";
import { TitleHelmet } from "~/components/suite-composite/title-helmet";
import UIErrorBoundary from "~/components/suite-ui/error-boundary";
import routeDefinitions from "~/routes/route-definitions";
import { intlAccessor } from "~/util/intl-accessor";
import { mutate } from "~/util/mutate";
import { parseZodFormErrors } from "~/util/validators/parse-zod-form-errors";

export function Component() {
  const intl = useIntl();
  const actionData = useActionData() as { errors?: Record<string, string> };

  const { state } = useNavigation();

  return (
    <>
      <TitleHelmet
        title={intl.formatMessage({
          id: "generic.label.change-password",
          defaultMessage: "Change password",
        })}
      />
      <Page>
        <PageCenter>
          <div className="mx-4 w-[360px] max-w-full">
            <div className="flex justify-center pb-10 pt-6">
              <LogoIcon className="text-primary" width={121} height={46} />
            </div>
            <Card>
              <CardHeader
                title={intl.formatMessage({
                  id: "generic.label.change-password",
                  defaultMessage: "Change password",
                })}
              />
              <UIErrorBoundary>
                <CardContent>
                  <Form method="POST" className="space-y-4">
                    <PasswordTextField
                      name="NewPassword"
                      label={intl.formatMessage({
                        id: "generic.label.new-password",
                        defaultMessage: "New password",
                      })}
                      isRequired
                      errorMessage={actionData?.errors?.NewPassword}
                    />
                    <Button type="submit" fullWidth isLoading={state !== "idle"}>
                      <FormattedMessage id="generic.label.confirm" defaultMessage="Confirm" />
                    </Button>
                  </Form>
                </CardContent>
              </UIErrorBoundary>
            </Card>
          </div>
        </PageCenter>
      </Page>
    </>
  );
}

Component.displayName = "ChangePasswordPage";

const newPasswordSchema = z.object({
  NewPassword: z.string().min(8),
});

const mutateResetPassword = mutate({
  service: Core.ResetUserPassword,
  messages: {
    successMessage: intlAccessor.formatMessage({
      id: "profile.password.dialog.change.success",
      defaultMessage: "Password changed successfully",
    }),
  },
});

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const token = params.token;
  if (!token) {
    return redirect(routeDefinitions.auth.forgotPassword.path);
  }

  const formData = zfd.formData(newPasswordSchema).safeParse(await request.formData());

  if (!formData.success) {
    const errors = parseZodFormErrors(formData.error);
    return {
      errors,
    };
  }

  const result = await mutateResetPassword(
    {
      NewPassword: formData.data.NewPassword,
      Token: token,
    },
    { authenticationToken: undefined },
  );

  if (result?.success) {
    return redirect(routeDefinitions.auth.login.path);
  }

  return null;
};
