import { RouteObject } from "react-router";

import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "~/routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "~/routes/lazy-with-retry";
import ModuleChapterRedirecter from "~/routes/module-chapter-redirecter";
import routeDefinitions from "~/routes/route-definitions";

const { stockManagement } = routeDefinitions;

export const stockManagementRoutes: RouteObject[] = [
  {
    path: stockManagement.module.path,
    lazy: lazyWithRetry(() => import("./stock-management")),
    children: [
      {
        index: true,
        element: <ModuleChapterRedirecter moduleCode={EModuleCode.STOCKMANAGEMENT} />,
      },

      {
        path: stockManagement.allocation.overview.path,
        lazy: lazyWithRetry(() => import("./stock-management.allocation")),
      },
      {
        path: stockManagement.allocation.new.path,
        lazy: lazyWithRetry(() => import("./stock-management.allocation.new")),
      },
      {
        path: stockManagement.allocation.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.allocation.$id"),
            import("./stock-management.allocation.$id.edit-general-details"),
          ]);

          return page;
        }),
        children: [
          {
            path: stockManagement.allocation.details.editGeneralInfo.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.allocation.$id.edit-general-details"),
            ),
          },
        ],
      },

      {
        path: stockManagement.orderFulfillments.overview.path,
        lazy: lazyWithRetry(() => import("./stock-management.order-fulfillments")),
      },
      {
        path: stockManagement.orderFulfillments.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.order-fulfillments.$id"),
            import("./stock-management.order-fulfillments.$id.ship-fulfillment"),
            import("./stock-management.order-fulfillments.$id.cancel-order-line-fulfillments"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.orderFulfillments.details.shipFulfillment.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.order-fulfillments.$id.ship-fulfillment"),
            ),
          },
          {
            path: stockManagement.orderFulfillments.details.cancelOrderLineFulfillments.path,
            lazy: lazyWithRetry(
              () =>
                import("./stock-management.order-fulfillments.$id.cancel-order-line-fulfillments"),
            ),
          },
        ],
      },

      {
        path: stockManagement.productCapacityPlans.overview.path,
        lazy: lazyWithRetry(() => import("./stock-management.product-capacity-plans")),
      },
      {
        path: stockManagement.productCapacityPlans.details.path,
        lazy: lazyWithRetry(() => import("./stock-management.product-capacity-plans.$id")),
      },

      {
        path: stockManagement.purchaseOrders.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.purchase-orders"),
            import("./stock-management.purchase-orders.upload-initial-replenishment"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.purchaseOrders.overview.uploadInitialReplenishment.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.purchase-orders.upload-initial-replenishment"),
            ),
          },
        ],
      },
      {
        path: stockManagement.purchaseOrders.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.purchase-orders.$id"),
            import("./stock-management.purchase-orders.$id.edit-general-info"),
            import("./stock-management.purchase-orders.$id.cancel-order"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.purchaseOrders.details.editGeneralInfo.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.purchase-orders.$id.edit-general-info"),
            ),
          },
          {
            path: stockManagement.purchaseOrders.details.cancelOrder.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.purchase-orders.$id.cancel-order"),
            ),
          },
        ],
      },
      {
        path: stockManagement.purchaseOrders.orderlineDetails.path,
        lazy: lazyWithRetry(() => import("./stock-management.purchase-orders.$id.$orderlineid")),
      },

      {
        path: stockManagement.availability.checkAvailability.path,
        lazy: lazyWithRetry(() => import("./stock-management.availability")),
      },
      {
        path: stockManagement.availability.results.path,
        lazy: lazyWithRetry(() => import("./stock-management.availability.results")),

        children: [
          {
            path: stockManagement.availability.results.relatedOrders.path,
            lazy: lazyWithRetry(
              () =>
                import("./stock-management.availability.results.related-orders.$productId.$ouId"),
            ),
          },
        ],
      },

      {
        path: stockManagement.replenishments.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.replenishments"),
            import("./stock-management.replenishments.proposals"),
            import("./stock-management.replenishments.replenishment-products"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.replenishments.proposals.overview.path,
            lazy: lazyWithRetry(async () => import("./stock-management.replenishments.proposals")),
            children: [
              {
                path: stockManagement.replenishments.proposals.new.path,
                lazy: lazyWithRetry(
                  async () => import("./stock-management.replenishments.proposals.new"),
                ),
              },
              {
                path: stockManagement.replenishments.proposals.retry.path,
                lazy: lazyWithRetry(
                  async () => import("./stock-management.replenishments.proposals.retry.$id"),
                ),
              },
            ],
          },
          {
            path: stockManagement.replenishments.replenishmentProducts.overview.path,

            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./stock-management.replenishments.replenishment-products"),
                import("./stock-management.replenishments.replenishment-products.$id.add-min-max"),
                import("./stock-management.replenishments.replenishment-products.$id.set-min-max"),
                import(
                  "./stock-management.replenishments.replenishment-products.$id.set-min-max.grid"
                ),
                import("./stock-management.replenishments.replenishment-products.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: stockManagement.replenishments.replenishmentProducts.addMinMax.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.replenishments.replenishment-products.$id.add-min-max"
                    ),
                ),
              },
              {
                path: stockManagement.replenishments.replenishmentProducts.setMinMax.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.replenishments.replenishment-products.$id.set-min-max"
                    ),
                ),
              },
              {
                path: stockManagement.replenishments.replenishmentProducts.setMinMax.grid.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.replenishments.replenishment-products.$id.set-min-max.grid"
                    ),
                ),
              },
              {
                path: stockManagement.replenishments.replenishmentProducts.deleteProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./stock-management.replenishments.replenishment-products.$id.delete"),
                ),
              },
            ],
          },
        ],
      },
      {
        path: stockManagement.replenishments.proposals.details.path,
        lazy: lazyWithRetry(async () => import("./stock-management.replenishments.proposals.$id")),
      },
      {
        path: stockManagement.replenishments.replenishmentProducts.download.path,
        lazy: lazyWithRetry(
          () => import("./stock-management.replenishments.replenishment-products.download"),
        ),
      },
      {
        path: stockManagement.rts.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.rts"),
            import("./stock-management.rts.recalls"),
            import("./stock-management.rts.requested-returns"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.rts.recalls.overview.path,
            lazy: lazyWithRetry(() => import("./stock-management.rts.recalls")),
          },
          {
            path: stockManagement.rts.requestedReturns.overview.path,
            lazy: lazyWithRetry(() => import("./stock-management.rts.requested-returns")),
          },
        ],
      },
      {
        path: stockManagement.rts.recalls.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.rts.recalls.$id"),
            import("./stock-management.rts.recalls.$id.edit"),
            import("./stock-management.rts.recalls.$id.edit-supplier.$ouId"),
            import("./stock-management.rts.recalls.$id.delete"),
            import("./stock-management.rts.recalls.$id.process"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.rts.recalls.edit.path,
            lazy: lazyWithRetry(() => import("./stock-management.rts.recalls.$id.edit")),
          },
          {
            path: stockManagement.rts.recalls.editSupplier.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.rts.recalls.$id.edit-supplier.$ouId"),
            ),
          },
          {
            path: stockManagement.rts.recalls.delete.path,
            lazy: lazyWithRetry(() => import("./stock-management.rts.recalls.$id.delete")),
          },
          {
            path: stockManagement.rts.recalls.process.path,
            lazy: lazyWithRetry(() => import("./stock-management.rts.recalls.$id.process")),
          },
        ],
      },
      {
        path: stockManagement.rts.recalls.new.path,
        lazy: lazyWithRetry(() => import("./stock-management.rts.recalls.new")),
      },
      {
        path: stockManagement.stockLocations.overview.path,
        lazy: lazyWithRetry(() => import("./stock-management.stock-locations")),
      },
      {
        path: stockManagement.stockLocations.create.path,
        lazy: lazyWithRetry(() => import("./stock-management.stock-locations.new")),
      },

      {
        path: stockManagement.stockLocations.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.stock-locations.$id"),
            import("./stock-management.stock-locations.$id.edit"),
            import("./stock-management.stock-locations.$id.set-settings.$ouId"),
            import("./stock-management.stock-locations.$id.delete-settings.$ouId"),
            import("./stock-management.stock-locations.$id.add-settings"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.stockLocations.details.edit.path,
            lazy: lazyWithRetry(() => import("./stock-management.stock-locations.$id.edit")),
          },
          {
            path: stockManagement.stockLocations.details.setSettings.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.stock-locations.$id.set-settings.$ouId"),
            ),
          },
          {
            path: stockManagement.stockLocations.details.addSettings.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.stock-locations.$id.add-settings"),
            ),
          },
          {
            path: stockManagement.stockLocations.details.deleteSettings.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.stock-locations.$id.delete-settings.$ouId"),
            ),
          },
        ],
      },

      {
        path: stockManagement.overviewAndMutations.path,
        lazy: lazyWithRetry(() => import("./stock-management.overview-and-mutations")),

        children: [
          {
            path: stockManagement.overviewAndMutations.stockOverview.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./stock-management.overview-and-mutations.stock-overview"),
                import(
                  "./stock-management.overview-and-mutations.stock-overview.related-orders.$productId.$ouId"
                ),
                import(
                  "./stock-management.overview-and-mutations.stock-overview.stock-mutations.$productId"
                ),
                import(
                  "./stock-management.overview-and-mutations.stock-overview.serial-numbers.$productId.$ouId.$stockLabelId"
                ),
                import(
                  "./stock-management.overview-and-mutations.stock-overview.download-overview"
                ),
                import(
                  "./stock-management.overview-and-mutations.stock-overview.download-fifo-overview"
                ),
                import(
                  "./stock-management.overview-and-mutations.stock-overview.cost-price-ledger.$productId"
                ),
                import("./stock-management.overview-and-mutations.stock-mutations"),
                import("./stock-management.overview-and-mutations.stock-mutations.download"),
                import("./stock-management.overview-and-mutations.stock-mutations.upload"),
              ]);
              return page;
            }),
            children: [
              {
                path: stockManagement.overviewAndMutations.stockOverview.overview.downloadOverview
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.overview-and-mutations.stock-overview.download-overview"
                    ),
                ),
              },
              {
                path: stockManagement.overviewAndMutations.stockOverview.overview
                  .downloadFifoOverview.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.overview-and-mutations.stock-overview.download-fifo-overview"
                    ),
                ),
              },
              {
                path: stockManagement.overviewAndMutations.stockOverview.overview.relatedOrders
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.overview-and-mutations.stock-overview.related-orders.$productId.$ouId"
                    ),
                ),
              },
              {
                path: stockManagement.overviewAndMutations.stockOverview.overview.stockMutations
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.overview-and-mutations.stock-overview.stock-mutations.$productId"
                    ),
                ),
              },
              {
                path: stockManagement.overviewAndMutations.stockOverview.overview.serialNumbers
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.overview-and-mutations.stock-overview.serial-numbers.$productId.$ouId.$stockLabelId"
                    ),
                ),
              },
              {
                path: stockManagement.overviewAndMutations.stockOverview.overview.costPriceLedger
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./stock-management.overview-and-mutations.stock-overview.cost-price-ledger.$productId"
                    ),
                ),
              },
            ],
          },
          {
            path: stockManagement.overviewAndMutations.stockMutations.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./stock-management.overview-and-mutations.stock-mutations"),
                import("./stock-management.overview-and-mutations.stock-mutations.download"),
                import("./stock-management.overview-and-mutations.stock-mutations.upload"),
              ]);
              return page;
            }),
            children: [
              {
                path: stockManagement.overviewAndMutations.stockMutations.overview.download.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./stock-management.overview-and-mutations.stock-mutations.download"),
                ),
              },
              {
                path: stockManagement.overviewAndMutations.stockMutations.overview.upload.path,
                lazy: lazyWithRetry(
                  () => import("./stock-management.overview-and-mutations.stock-mutations.upload"),
                ),
              },
            ],
          },
        ],
      },

      {
        path: stockManagement.shipping.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.shipping"),
            import("./stock-management.shipping.carriers"),
            import("./stock-management.shipping.shipping-methods"),
            import("./stock-management.shipping.shipping-restrictions"),
            import("./stock-management.shipping.shipment-identifiers"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.shipping.carriers.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./stock-management.shipping.carriers"),
                import("./stock-management.shipping.carriers.new"),
                import("./stock-management.shipping.carriers.$id.edit"),
              ]);
              return page;
            }),
            children: [
              {
                path: stockManagement.shipping.carriers.new.path,
                lazy: lazyWithRetry(() => import("./stock-management.shipping.carriers.new")),
              },
              {
                path: stockManagement.shipping.carriers.edit.path,
                lazy: lazyWithRetry(() => import("./stock-management.shipping.carriers.$id.edit")),
              },
            ],
          },
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.overview.path,
            lazy: lazyWithRetry(() => import("./stock-management.shipping.shipping-methods")),
          },
          {
            path: stockManagement.shipping.shippingRestrictions.overview.path,
            lazy: lazyWithRetry(() => import("./stock-management.shipping.shipping-restrictions")),
          },
          {
            path: stockManagement.shipping.shipmentIdentifiers.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./stock-management.shipping.shipment-identifiers"),
                import("./stock-management.shipping.shipment-identifiers.new"),
                import("./stock-management.shipping.shipment-identifiers.$id.edit"),
                import("./stock-management.shipping.shipment-identifiers.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: stockManagement.shipping.shipmentIdentifiers.new.path,
                lazy: lazyWithRetry(
                  () => import("./stock-management.shipping.shipment-identifiers.new"),
                ),
              },
              {
                path: stockManagement.shipping.shipmentIdentifiers.edit.path,
                lazy: lazyWithRetry(
                  () => import("./stock-management.shipping.shipment-identifiers.$id.edit"),
                ),
              },
              {
                path: stockManagement.shipping.shipmentIdentifiers.delete.path,
                lazy: lazyWithRetry(
                  () => import("./stock-management.shipping.shipment-identifiers.$id.delete"),
                ),
              },
            ],
          },
        ],
      },

      {
        path: stockManagement.shipping.shippingRestrictions.new.path,
        lazy: lazyWithRetry(() => import("./stock-management.shipping.shipping-restrictions.new")),
      },
      {
        path: stockManagement.shipping.shippingRestrictions.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.shipping.shipping-restrictions.$id"),
            import("./stock-management.shipping.shipping-restrictions.$id.edit"),
            import("./stock-management.shipping.shipping-restrictions.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.shipping.shippingRestrictions.edit.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.shipping.shipping-restrictions.$id.edit"),
            ),
          },
          {
            path: stockManagement.shipping.shippingRestrictions.delete.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.shipping.shipping-restrictions.$id.delete"),
            ),
          },
        ],
      },

      {
        path: stockManagement.shipping.shippingMethodsAndCosts.new.path,
        lazy: lazyWithRetry(() => import("./stock-management.shipping.shipping-methods.new")),
      },

      {
        path: stockManagement.shipping.shippingMethodsAndCosts.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.shipping.shipping-methods.$id"),
            import("./stock-management.shipping.shipping-methods.$id.edit"),
            import("./stock-management.shipping.shipping-methods.$id.shipping-costs.new"),
            import(
              "./stock-management.shipping.shipping-methods.$id.shipping-costs.$shippingCostId.edit"
            ),
            import(
              "./stock-management.shipping.shipping-methods.$id.shipping-costs.$shippingCostId.delete"
            ),
            import(
              "./stock-management.shipping.shipping-methods.$id.custom-fields.$customFieldId.delete"
            ),
            import(
              "./stock-management.shipping.shipping-methods.$id.custom-fields.$customFieldId.edit"
            ),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.details.edit.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.shipping.shipping-methods.$id.edit"),
            ),
          },
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.details.shippingCosts.new.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.shipping.shipping-methods.$id.shipping-costs.new"),
            ),
          },
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.details.shippingCosts.edit.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./stock-management.shipping.shipping-methods.$id.shipping-costs.$shippingCostId.edit"
                ),
            ),
          },
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.details.shippingCosts.delete
              .path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./stock-management.shipping.shipping-methods.$id.shipping-costs.$shippingCostId.delete"
                ),
            ),
          },
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.details.customFields.delete.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./stock-management.shipping.shipping-methods.$id.custom-fields.$customFieldId.delete"
                ),
            ),
          },
          {
            path: stockManagement.shipping.shippingMethodsAndCosts.details.customFields.edit.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./stock-management.shipping.shipping-methods.$id.custom-fields.$customFieldId.edit"
                ),
            ),
          },
        ],
      },

      {
        path: stockManagement.receiveGoods.overview.path,
        lazy: lazyWithRetry(() => import("./stock-management.receive-goods")),
        children: [
          {
            path: stockManagement.receiveGoods.overview.fullyReceive.path,
            lazy: lazyWithRetry(() => import("./stock-management.receive-goods.$id.fully-receive")),
          },
          {
            path: stockManagement.receiveGoods.overview.markAsUnreceived.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.receive-goods.$id.mark-as-unreceived"),
            ),
          },
        ],
      },
      {
        path: stockManagement.receiveGoods.details.path,
        id: "stock-management.receive-goods.$id",
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./stock-management.receive-goods.$id"),
            import("./stock-management.receive-goods.$id.edit-receive-method"),
            import("./stock-management.receive-goods.$id.edit-received-goods"),
          ]);
          return page;
        }),
        children: [
          {
            path: stockManagement.receiveGoods.details.editReceiveMethod.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.receive-goods.$id.edit-receive-method"),
            ),
          },
          {
            path: stockManagement.receiveGoods.details.editReceivedGoods.path,
            lazy: lazyWithRetry(
              () => import("./stock-management.receive-goods.$id.edit-received-goods"),
            ),
          },
        ],
      },

      /**
       * EVA Intent Handler
       */
      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },
];
