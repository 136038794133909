import { IndexRouteObject, LazyRouteFunction, NonIndexRouteObject } from "react-router";

const sessionKey = "lazyWithRetry";

const lazyWithRetry = (
  componentImport: LazyRouteFunction<NonIndexRouteObject> | LazyRouteFunction<IndexRouteObject>,
) => {
  return async () => {
    const hasRefreshed = globalThis.sessionStorage.getItem(sessionKey) || "false";

    try {
      globalThis.sessionStorage.setItem(sessionKey, "false");
      return await componentImport();
    } catch (error) {
      console.log("[APP]: Could not fetch chunk, reloading page");

      if (hasRefreshed === "false") {
        globalThis.sessionStorage.setItem(sessionKey, "true");
        globalThis.location.reload();
      }

      if (hasRefreshed === "true") throw new Error("chunkLoadError");
    }
    return componentImport();
  };
};

export default lazyWithRetry;
