import { Navigate, RouteObject } from "react-router";

import routeDefinitions from "../route-definitions";

import { NavigateWithParams } from "~/components/routing";
import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "~/routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "~/routes/lazy-with-retry";
import ModuleChapterRedirecter from "~/routes/module-chapter-redirecter";
import promotions from "~/routes/route-definitions/promotions";

// Parent is root /
export const promotionsRoutes: RouteObject[] = [
  {
    path: promotions.module.path,
    lazy: lazyWithRetry(() => import("./promotions")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.PROMOTIONS} /> },
      {
        path: promotions.promotions.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.promotions._index"),
            import("./promotions.promotions.search-coupons"),
          ]);
          return page;
        }),
        children: [
          {
            path: promotions.promotions.overview.searchCoupons.path,
            lazy: lazyWithRetry(() => import("./promotions.promotions.search-coupons")),
          },
        ],
      },
      {
        path: promotions.promotions.create.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.promotions.new"),
            import("./promotions.promotions.new.general-information"),
            import("./promotions.promotions.new.display-options"),
            import("./promotions.promotions.new.financial-information"),
            import("./promotions.promotions.new.action"),
            import("./promotions.promotions.new.conditions"),
            import("./promotions.promotions.new.verification"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams
                path={promotions.promotions.create.generalInformation.path}
                replace
              />
            ),
          },
          {
            path: promotions.promotions.create.generalInformation.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.new.general-information"),
                import("./promotions.promotions.new.general-information.create-ou-set"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.create.generalInformation.createOuSet.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.new.general-information.create-ou-set"),
                ),
              },
            ],
          },
          {
            path: promotions.promotions.create.displayOptions.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.new.display-options"),
                import("./promotions.promotions.new.display-options.upload-image"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.create.displayOptions.uploadImage.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.new.display-options.upload-image"),
                ),
              },
            ],
          },
          {
            path: promotions.promotions.create.financialInfo.path,
            lazy: lazyWithRetry(() => import("./promotions.promotions.new.financial-information")),
          },
          {
            path: promotions.promotions.create.action.path,
            lazy: lazyWithRetry(() => import("./promotions.promotions.new.action")),
          },
          {
            path: promotions.promotions.create.conditions.path,
            lazy: lazyWithRetry(() => import("./promotions.promotions.new.conditions")),
          },
          {
            path: promotions.promotions.create.verification.path,
            lazy: lazyWithRetry(() => import("./promotions.promotions.new.verification")),
          },
        ],
      },
      {
        path: promotions.promotions.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.promotions.$id"),
            import("./promotions.promotions.$id.general-information"),
            import("./promotions.promotions.$id.display-options"),
            import("./promotions.promotions.$id.financial-information"),
            import("./promotions.promotions.$id.action"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams
                path={promotions.promotions.details.generalInformation.path}
                replace
              />
            ),
          },
          {
            path: promotions.promotions.details.generalInformation.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.general-information"),
                import("./promotions.promotions.$id.general-information.edit"),
                import("./promotions.promotions.$id.general-information.edit-active-period"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.details.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.general-information.edit"),
                ),
              },
              {
                path: promotions.promotions.details.generalInformation.editActivePeriod.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./promotions.promotions.$id.general-information.edit-active-period"),
                ),
              },
            ],
          },
          {
            path: promotions.promotions.details.displayOptions.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.display-options"),
                import("./promotions.promotions.$id.display-options.edit"),
                import("./promotions.promotions.$id.display-options.upload-image"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.details.displayOptions.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.display-options.edit"),
                ),
              },
              {
                path: promotions.promotions.details.displayOptions.uploadImage.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.display-options.upload-image"),
                ),
              },
              {
                path: promotions.promotions.details.displayOptions.customFields.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.display-options.custom-fields.$customFieldId.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.displayOptions.customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.display-options.custom-fields.$customFieldId.delete"
                    ),
                ),
              },
            ],
          },
          {
            path: promotions.promotions.details.financialInfo.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.financial-information"),
                import("./promotions.promotions.$id.financial-information.edit"),
                import("./promotions.promotions.$id.financial-information.edit-recurring-benefits"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.details.financialInfo.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.financial-information.edit"),
                ),
              },
              {
                path: promotions.promotions.details.financialInfo.editRecurringBenefits.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.financial-information.edit-recurring-benefits"
                    ),
                ),
              },
            ],
          },
          {
            path: promotions.promotions.details.action.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.action"),
                import("./promotions.promotions.$id.action.edit"),
                import("./promotions.promotions.$id.action.edit-financial-implications"),
                import("./promotions.promotions.$id.action.product-sets.$productSetType.new"),
                import(
                  "./promotions.promotions.$id.action.product-sets.$productSetIndex.edit-general-details"
                ),
                import(
                  "./promotions.promotions.$id.action.product-sets.$productSetIndex.edit-content"
                ),
                import(
                  "./promotions.promotions.$id.action.product-sets.edit-financial-implications"
                ),
                import("./promotions.promotions.$id.action.sliding-discount.edit"),
                import("./promotions.promotions.$id.action.sliding-discount.tiers.$tierIndex.edit"),
                import("./promotions.promotions.$id.action.sliding-discount.tiers.add"),
                import(
                  "./promotions.promotions.$id.action.sliding-discount.tiers.$tierIndex.delete"
                ),
                import("./promotions.promotions.$id.action.sliding-points.tiers.$tierIndex.edit"),
                import("./promotions.promotions.$id.action.sliding-points.tiers.add"),
                import("./promotions.promotions.$id.action.sliding-points.tiers.$tierIndex.delete"),
                import("./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.edit"),
                import("./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.delete"),
                import("./promotions.promotions.$id.action.pick-a-product.tiers.add"),
                import(
                  "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.products.add"
                ),
                import(
                  "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.products.$productId.edit"
                ),
                import(
                  "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.products.$productId.delete"
                ),
                import("./promotions.promotions.$id.action.age-discount.tiers.$tierIndex.delete"),
                import("./promotions.promotions.$id.action.age-discount.tiers.$tierIndex.edit"),
                import("./promotions.promotions.$id.action.age-discount.tiers.add"),
                import("./promotions.promotions.$id.action.custom-fields.discounts.add"),
                import(
                  "./promotions.promotions.$id.action.custom-fields.discounts.$discountIndex.edit"
                ),
                import(
                  "./promotions.promotions.$id.action.custom-fields.discounts.$discountIndex.delete"
                ),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.details.action.edit.path,
                lazy: lazyWithRetry(() => import("./promotions.promotions.$id.action.edit")),
              },
              {
                path: promotions.promotions.details.action.editFinancialImplications.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.edit-financial-implications"),
                ),
              },
              {
                path: promotions.promotions.details.action.productSets.new.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./promotions.promotions.$id.action.product-sets.$productSetType.new"),
                ),
              },
              {
                path: promotions.promotions.details.action.productSets.editGeneralDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.product-sets.$productSetIndex.edit-general-details"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.productSets.editContent.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.product-sets.$productSetIndex.edit-content"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.productSets.editFinancialImplications
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.product-sets.edit-financial-implications"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.getAProduct.editProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.get-a-product.edit-product.$productId"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingDiscount.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.sliding-discount.edit"),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingDiscount.tiers.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.sliding-discount.tiers.$tierIndex.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingDiscount.tiers.add.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.sliding-discount.tiers.add"),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingDiscount.tiers.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.sliding-discount.tiers.$tierIndex.delete"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingPoints.tiers.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.sliding-points.tiers.$tierIndex.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingPoints.tiers.add.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.sliding-points.tiers.add"),
                ),
              },
              {
                path: promotions.promotions.details.action.slidingPoints.tiers.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.sliding-points.tiers.$tierIndex.delete"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.pickAProduct.tiers.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.pickAProduct.tiers.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.delete"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.pickAProduct.tiers.products.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.products.add"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.pickAProduct.tiers.products.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.products.$productId.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.pickAProduct.tiers.products.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.pick-a-product.tiers.$tierIndex.products.$productId.delete"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.pickAProduct.tiers.add.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.pick-a-product.tiers.add"),
                ),
              },
              {
                path: promotions.promotions.details.action.ageDiscount.tiers.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.age-discount.tiers.$tierIndex.delete"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.ageDiscount.tiers.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./promotions.promotions.$id.action.age-discount.tiers.$tierIndex.edit"),
                ),
              },
              {
                path: promotions.promotions.details.action.ageDiscount.tiers.add.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.age-discount.tiers.add"),
                ),
              },
              {
                path: promotions.promotions.details.action.customFields.discounts.add.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.action.custom-fields.discounts.add"),
                ),
              },
              {
                path: promotions.promotions.details.action.customFields.discounts.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.custom-fields.discounts.$discountIndex.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.action.customFields.discounts.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.action.custom-fields.discounts.$discountIndex.delete"
                    ),
                ),
              },
            ],
          },
          {
            path: promotions.promotions.details.conditions.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.conditions"),
                import("./promotions.promotions.$id.conditions.edit-condition-type"),
                import(
                  "./promotions.promotions.$id.conditions.$conditionType.product-sets.$productSetType.new"
                ),
                import(
                  "./promotions.promotions.$id.conditions.$conditionType.product-sets.$productSetIndex.edit-content"
                ),
                import(
                  "./promotions.promotions.$id.conditions.$conditionType.product-sets.$productSetIndex.edit-general-details"
                ),
                import("./promotions.promotions.$id.conditions.order-amount.edit"),
                import("./promotions.promotions.$id.conditions.stock-label.edit"),
                import("./promotions.promotions.$id.conditions.order-properties.edit"),
                import("./promotions.promotions.$id.conditions.customer-age.edit"),
                import("./promotions.promotions.$id.conditions.loyalty-points.edit"),
                import("./promotions.promotions.$id.conditions.order-type.edit"),
                import("./promotions.promotions.$id.conditions.products.edit"),
                import("./promotions.promotions.$id.conditions.customer.edit"),
                import("./promotions.promotions.$id.conditions.customer.filter.$filterType"),
                import("./promotions.promotions.$id.conditions.rituals-member.edit"),
                import("./promotions.promotions.$id.conditions.rituals-welcome-gift.edit"),
                import("./promotions.promotions.$id.conditions.user-role.edit"),
                import("./promotions.promotions.$id.conditions.user-type.edit"),
                import("./promotions.promotions.$id.conditions.original-ou.edit"),
                import("./promotions.promotions.$id.conditions.coupon-validity.edit"),
                import(
                  "./promotions.promotions.$id.conditions.custom-fields.$custom-field-type.edit"
                ),
                import(
                  "./promotions.promotions.$id.conditions.custom-fields.$custom-field-type.add-condition"
                ),
                import(
                  "./promotions.promotions.$id.conditions.custom-fields.$custom-field-type.edit-condition.$conditionIndex"
                ),
                import(
                  "./promotions.promotions.$id.conditions.product-requirement.requirements.new"
                ),
                import(
                  "./promotions.promotions.$id.conditions.product-requirement.requirements.$requirementIndex.edit"
                ),
                import("./promotions.promotions.$id.conditions.new.$conditionType"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.details.conditions.editConditionType.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.edit-condition-type"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.orderAmount.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.order-amount.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.stockLabel.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.stock-label.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.orderProperties.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.order-properties.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.customerAge.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.customer-age.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.loyaltyPoints.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.loyalty-points.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.orderType.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.order-type.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.products.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.products.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.customer.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.customer.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.customer.setFilter.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./promotions.promotions.$id.conditions.customer.filter.$filterType"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.ritualsMember.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.rituals-member.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.ritualsWelcomeGift.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.rituals-welcome-gift.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.userRole.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.user-role.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.userType.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.user-type.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.originalOu.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.original-ou.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.couponValidity.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.coupon-validity.edit"),
                ),
              },
              {
                path: promotions.promotions.details.conditions.customFields.editDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.custom-fields.$custom-field-type.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.customFields.addCondition.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.custom-fields.$custom-field-type.add-condition"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.customFields.editCondition.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.custom-fields.$custom-field-type.edit-condition.$conditionIndex"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.productRequirement.addRequirement
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.product-requirement.requirements.new"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.productRequirement.editRequirement
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.product-requirement.requirements.$requirementIndex.edit"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.productSets.new.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.$conditionType.product-sets.$productSetType.new"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.productSets.editContent.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.$conditionType.product-sets.$productSetIndex.edit-content"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.productSets.editGeneralDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.promotions.$id.conditions.$conditionType.product-sets.$productSetIndex.edit-general-details"
                    ),
                ),
              },
              {
                path: promotions.promotions.details.conditions.addCondition.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.conditions.new.$conditionType"),
                ),
              },
            ],
          },
          {
            path: routeDefinitions.promotions.promotions.details.coupons.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.coupons"),
                import("./promotions.promotions.$id.coupons.handler"),
                import("./promotions.promotions.$id.coupons.new.multiple"),
                import("./promotions.promotions.$id.coupons.new.custom"),
                import("./promotions.promotions.$id.coupons.$couponId"),
                import("./promotions.promotions.$id.coupons.$couponId.edit"),
              ]);
              return page;
            }),
            children: [
              {
                path: routeDefinitions.promotions.promotions.details.coupons.editHandler.path,
                lazy: lazyWithRetry(() => import("./promotions.promotions.$id.coupons.handler")),
              },
              {
                path: routeDefinitions.promotions.promotions.details.coupons.create.multiple.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.coupons.new.multiple"),
                ),
              },
              {
                path: routeDefinitions.promotions.promotions.details.coupons.create.custom.path,
                lazy: lazyWithRetry(() => import("./promotions.promotions.$id.coupons.new.custom")),
              },
              {
                path: routeDefinitions.promotions.promotions.details.coupons.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.promotions.$id.coupons.$couponId.edit"),
                ),
              },
              {
                path: routeDefinitions.promotions.promotions.details.coupons.details.path,
                lazy: lazyWithRetry(() => import("./promotions.promotions.$id.coupons.$couponId")),
              },
            ],
          },
          {
            path: promotions.promotions.details.summary.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.promotions.$id.summary"),
                import("./promotions.promotions.$id.summary.verify"),
              ]);
              return page;
            }),
            children: [
              {
                path: promotions.promotions.details.summary.verify.path,
                lazy: lazyWithRetry(() => import("./promotions.promotions.$id.summary.verify")),
              },
            ],
          },
        ],
      },
      {
        path: "home",
        children: [
          {
            index: true,
            element: <Navigate to={promotions.promotions.overview.path} />,
          },
          {
            path: ":id",
            element: <NavigateWithParams path={promotions.promotions.details.path} />,
          },
        ],
      },
      {
        path: promotions.personalizedPromotions.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.personalized-promotions"),
            import("./promotions.personalized-promotions.create"),
            import("./promotions.personalized-promotions.$id.edit"),
            import("./promotions.personalized-promotions.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: promotions.personalizedPromotions.overview.create.path,
            lazy: lazyWithRetry(() => import("./promotions.personalized-promotions.create")),
          },

          {
            path: promotions.personalizedPromotions.overview.edit.path,
            lazy: lazyWithRetry(() => import("./promotions.personalized-promotions.$id.edit")),
          },

          {
            path: promotions.personalizedPromotions.overview.delete.path,
            lazy: lazyWithRetry(() => import("./promotions.personalized-promotions.$id.delete")),
          },
        ],
      },
      {
        path: promotions.campaigns.wrapper.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.campaigns"),
            import("./promotions.campaigns._index"),
            import("./promotions.campaigns.new"),
            import("./promotions.campaigns.$id"),
            import("./promotions.campaigns.$id.edit-budget"),
            import("./promotions.campaigns.$id.edit-information"),
            import("./promotions.campaigns.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./promotions.campaigns._index")),
          },
          {
            path: promotions.campaigns.create.path,
            lazy: lazyWithRetry(() => import("./promotions.campaigns.new")),
          },
          {
            path: promotions.campaigns.details.path,
            lazy: lazyWithRetry(() => import("./promotions.campaigns.$id")),

            children: [
              {
                path: promotions.campaigns.details.editInformation.path,
                lazy: lazyWithRetry(() => import("./promotions.campaigns.$id.edit-information")),
              },
              {
                path: promotions.campaigns.details.editBudget.path,
                lazy: lazyWithRetry(() => import("./promotions.campaigns.$id.edit-budget")),
              },
              {
                path: promotions.campaigns.details.delete.path,
                lazy: lazyWithRetry(() => import("./promotions.campaigns.$id.delete")),
              },
            ],
          },
        ],
      },

      {
        path: promotions.templates.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./promotions.discount-templates._index")),
          },
          {
            path: promotions.templates.details.path,
            lazy: lazyWithRetry(() => import("./promotions.discount-templates.$id")),
          },
        ],
      },

      {
        path: promotions.loyaltyPrograms.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.loyalty-programs._index"),
            import("./promotions.loyalty-programs.loyalty-programs-overview"),
            import("./promotions.loyalty-programs.loyalty-program-groups"),
          ]);

          return page;
        }),
        children: [
          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-programs-overview"),
                import("./promotions.loyalty-programs.loyalty-programs-overview.new"),
                import("./promotions.loyalty-programs.loyalty-programs-overview.$id"),
              ]);

              return page;
            }),
          },
          {
            path: promotions.loyaltyPrograms.loyaltyProgramGroups.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-program-groups"),
                import("./promotions.loyalty-programs.loyalty-program-groups.new"),
                import("./promotions.loyalty-programs.loyalty-program-groups.$id.edit"),
                import("./promotions.loyalty-programs.loyalty-program-groups.$id.delete"),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramGroups.create.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.loyalty-programs.loyalty-program-groups.new"),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramGroups.edit.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.loyalty-programs.loyalty-program-groups.$id.edit"),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramGroups.delete.path,
                lazy: lazyWithRetry(
                  () => import("./promotions.loyalty-programs.loyalty-program-groups.$id.delete"),
                ),
              },
            ],
          },
        ],
      },

      {
        path: promotions.loyaltyPrograms.loyaltyProgramsOverview.create.path,
        lazy: lazyWithRetry(
          () => import("./promotions.loyalty-programs.loyalty-programs-overview.new"),
        ),
      },

      {
        path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./promotions.loyalty-programs.loyalty-programs-overview.$id"),
            import(
              "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information"
            ),
            import(
              "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules"
            ),
            import("./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types"),
            import("./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions"),
            import(
              "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations"
            ),
          ]);

          return page;
        }),
        children: [
          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
              .path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.$customFieldID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.$priceListID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.$ouSetID.delete"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .editGeneralInformation.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.edit-general-information"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .customFields.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.custom-fields.$customFieldID.delete"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .priceLists.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .priceLists.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.price-lists.$priceListID.delete"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .ouSets.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .ouSets.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.$ouSetID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.generalInformation
                  .ouSets.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.general-information.ou-sets.$ouSetID.delete"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.$conditionID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.$conditionID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.$conditionID.edit"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.delete"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.delete.$conditionID"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.orderAmount.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.orderAmount.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.order-amount.$conditionID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.productSet.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.productSet.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.product-set.$conditionID.edit"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.consumer.add.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.new"
                    ),
                ),
              },
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.pointSavingRules
                  .conditions.consumer.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.point-saving-rules.$ruleID.conditions.consumer.$conditionID.edit"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types"),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-search-template"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids.$productID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.new-bulk"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.($propertyName)"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.$propertyName.delete"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.delete
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.create
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes.edit
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.edit"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productSearchTemplate.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-search-template"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productIDs.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productIDs.deleteProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-ids.$productID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productFilters.createBulk.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.new-bulk"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productFilters.createOrEdit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.($propertyName)"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.productFilters.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.product-filters.$propertyName.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.paymentTypes
                  .productLimitationData.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.payment-types.$paymentTypeID.delete-products"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions"),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.delete.$conditionID"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.$conditionID.edit-general-information"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.$conditionID.edit-general-information"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.delete
                  .path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.delete.$conditionID"
                    ),
                ),
              },

              /** Customer */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.edit-general-information"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .filters.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions.customer
                  .filters.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.customer.$conditionID.$filter.delete"
                    ),
                ),
              },

              /** User custom fields */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.edit-general-information"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.customFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .userCustomFields.customFields.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.user-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                    ),
                ),
              },

              /** Order custom fields */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.edit-general-information"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.customFields.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.customFields.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderCustomFields.customFields.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-custom-fields.$conditionID.custom-fields.$customFieldID.$index.edit"
                    ),
                ),
              },

              /** Order type */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderType.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderType.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-type.$conditionID.edit-general-information"
                    ),
                ),
              },

              /** Order properties */
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderProperties.create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.conditions
                  .orderProperties.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.conditions.order-properties.$conditionID.edit-general-information"
                    ),
                ),
              },
            ],
          },

          {
            path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
              .path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.new"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete-products"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.edit"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-search-template"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids.$productID.delete"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.new-bulk"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.($propertyName)"
                ),
                import(
                  "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.$propertyName.delete"
                ),
              ]);

              return page;
            }),
            children: [
              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .create.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.new"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .edit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.edit"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.delete-products"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productSearchTemplate.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-search-template"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productIDs.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productIDs.deleteProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-ids.$productID.delete"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productFilters.createBulk.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.new-bulk"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productFilters.createOrEdit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.($propertyName)"
                    ),
                ),
              },

              {
                path: promotions.loyaltyPrograms.loyaltyProgramsOverview.details.productLimitations
                  .productLimitationData.productFilters.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./promotions.loyalty-programs.loyalty-programs-overview.$id.product-limitations.$productLimitationID.product-filters.$propertyName.delete"
                    ),
                ),
              },
            ],
          },
        ],
      },

      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },

      {
        path: ":id",
        element: <NavigateWithParams path={promotions.promotions.details.path} />,
      },
    ],
  },
];
